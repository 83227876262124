.login-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 80px;
  border: 0.5px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align the content inside the container */
}

body {
  background-image: url('../Img-src/login-back.png');
  background-size: auto;
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  min-height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  /* background-color: rgb(202, 125, 31); */
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 20px;
  /* background-color: rgb(202, 125, 31); */

}

label {
  margin-bottom: 5px;
}

input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-top: 20px;
}

button:hover {
  background-color: #0069d9;
}

button:active {
  transform: scale(0.95);
}
