html, body {
    width: 100vw;
}

#all {
    width: 100%;
}

.App {
    width: 100%;
    margin-top: 20px;
}