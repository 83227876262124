.PatientForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  display: block;
  margin-top: 1rem;
  font-weight: bold;
}

select,
input,
textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
}

select {
  width: auto;
}

textarea {
  resize: vertical;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.checkbox-label input[type="checkbox"] {
  margin-right: 0.5rem;
}

button[type="submit"] {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #0077ff;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

button[type="submit"]:hover {
  background-color: #0057d1;
}
