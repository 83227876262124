/* .ticket-data {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
  }
  
  input {
    margin-right: 10px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  label {
    margin-right: 5px;
  }
  
  select {
    padding: 5px;
    margin-right: 10px;
  }
   */
  /* StatusChange.css */

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.ticket-data {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  animation: ticket-bounce 0.5s ease-in-out;
}

@keyframes ticket-bounce {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.ticket-data h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.ticket-data p {
  font-style: italic;
  margin-top: 10px;
}

input[type="text"] {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
  animation: button-pulse 1s ease-in-out infinite alternate;
}

@keyframes button-pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

button:hover {
  background-color: #0056b3;
}

label {
  margin-right: 5px;
  font-weight: bold;
}

select {
  padding: 5px;
  margin-right: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

p {
  font-weight: bold;
  margin-top: 10px;
}
