/* ApptList.css */

/* Style for the appointments table */
table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Style for the table headers */
thead th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 12px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

/* Style for the table rows */
tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

/* Style for the table data */
td {
  padding: 12px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

/* Style for the table heading */
h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
h1 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
