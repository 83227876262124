/* Style for the container */
div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  background-image: url('header-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

/* Style for the form header */
h1 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}


/* Style for the label */
label {
  display: block;
  margin-top: 1rem;
  font-weight: bold;
}

/* Style for the select dropdown */
select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
}

/* Style for the input fields */
input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
}

/* Style for the submit button */
button {
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #0077ff;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
  align-self: center; /* Added alignment */
}

button:hover {
  background-color: #0057d1;
}

/* Style for the checkbox */
input[type="checkbox"] {
  margin-right: 0.5rem;
}
