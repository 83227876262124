body {
    margin: 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .footer {
    background: #000;
    padding: 30px 0;
    font-family: 'Play', sans-serif;
    text-align: center;
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
  }
  
  .footer .row {
    width: 100%;
    margin: 1% 0;
    padding: 0.6% 0;
    color: gray;
    font-size: 0.8em;
  }
  
  .footer .row a {
    text-decoration: none;
    color: gray;
    transition: 0.5s;
  }
  
  .footer .row a:hover {
    color: #fff;
  }
  
  .footer .row ul {
    width: 100%;
  }
  
  .footer .row ul li {
    display: inline-block;
    margin: 0 30px;
  }
  
  .footer .row a i {
    font-size: 2em;
    margin: 0 1%;
  }