/* .ticket-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  body {
    background-image: url('header-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .form-field {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type='text'],
  textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button[type='submit'] {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button[type='submit']:hover {
    background-color: #0056b3;
  }
   */
   .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  
  .form-row .form-group {
    flex-basis: 50%;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
  }
  
  .form-check-label {
    margin-right: 5px;
  }
  
  .btn-submit {
    margin-top: 10px;
  }
  