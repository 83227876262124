.navbar-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.navbar-nav2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 10px;
  font-weight: bold;
  transition: color 0.3s ease-in-out; /* Add transition property for smooth effect */
}
.nav-item:hover {
  color: yellow; 
}


.nav-link {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

.cardsdisplay {
  margin-top: 20px;
  font-weight: bold;
}

.navgrid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 80px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.navgrid-container-top {
  display: grid;
  margin: 50px;
  grid-gap: 80px;

}

.card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.card .card-img-top {
  height: 150px;
  object-fit: contain;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card .card-footer {
  background-color: #f5f5f5;
  padding: 10px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: bold;
}

.card .card-footer .nav-link {
  color: #000000;
  text-decoration: none;
  transition: color 0.3s;
}

.card .card-footer .nav-link:hover {
  color: #ffdc00;
}

.cards-place {
  display: grid;
}
