.ticket-data {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
  }
  
  input {
    margin-right: 10px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  